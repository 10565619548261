import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  instantQuote_acceptQuote,
  instantQuote_generateQuote,
  instantQuote_rejectQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { GeneratedInstantQuoteViewer, OrderServiceHeader } from '@src/components';
import { BushTrimmingForm } from '@src/components/organisms/forms';
import { scrollToBottom } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import type { BushTrimmingFormData } from '@src/components/organisms/forms/BushTrimmingForm/types';

export function OrderBushTrimmingScreen() {
  const dispatch = useDispatch();
  const theme = useAppTheme();
  const [params] = useSearchParams();

  const { navigate } = useRouteNavigation();
  const errorModal = useModal(ModalTemplate.MESSAGES);
  const rejectQuoteModal = useModal(ModalTemplate.FEEDBACK);

  const propertyId: number = useSelector(properties_currentPropertyIdSelector);
  const instantQuote = useSelector((state) => state.instantQuote.currentQuote);
  const isLoading = useSelector((state) => state.instantQuote.loadingStatus.isLoading);

  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    showQuote && scrollToBottom();
  }, [showQuote]);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<BushTrimmingFormData>({
    defaultValues: {
      haulOff: 0,
      frequency: 28,
      shrubsLessThan5: '0',
      shrubs5To10: '0',
      shrubsMoreThan10: '0',
      details: '',
      location: [],
      images: null,
    },
    resolver: zodResolver(
      z.object({
        shrubsLessThan5: z.string().min(1, { message: t('required') }),
        shrubs5To10: z.string().min(1, { message: t('required') }),
        shrubsMoreThan10: z.string().min(1, { message: t('required') }),
        location: z
          .string()
          .array()
          .nonempty({ message: t('required') }),
        haulOff: z.number(),
        frequency: z.number(),
        details: z.string(),
        images: z.array(z.any()).nullable(),
      }),
    ),
  });

  useAutoScrollOnFormErrors({ errors, submitCount });

  const validateBushes = useCallback(
    ({ shrubsLessThan5, shrubs5To10, shrubsMoreThan10 }: Record<string, string>) => {
      if ([shrubsLessThan5, shrubs5To10, shrubsMoreThan10].every((value) => value === '0')) {
        errorModal.show({
          messages: [t('bushTrimmingForm.noBushesError')],
          buttonText: t('close'),
        });
        return false;
      }

      return true;
    },
    [errorModal],
  );

  const submit = useCallback(
    async (data: BushTrimmingFormData) => {
      const {
        shrubsLessThan5,
        shrubs5To10,
        shrubsMoreThan10,
        images,
        frequency,
        details,
        location,
        haulOff,
      } = data;
      const valid = validateBushes({ shrubsLessThan5, shrubs5To10, shrubsMoreThan10 });

      const locations = location.reduce(
        (acc, cur) => {
          return {
            ...acc,
            [cur.replace('bushes_property_location_', '')]: true,
          };
        },
        {
          back: false,
          front: false,
          full: false,
          left: false,
          right: false,
        },
      );

      if (valid) {
        await dispatch(
          instantQuote_generateQuote({
            property_id: propertyId,
            type: 'bush_trimming',
            pendingQuote: {
              bushes_0ft_5ft_count: Number(shrubsLessThan5),
              bushes_5ft_10ft_count: Number(shrubs5To10),
              bushes_over_10ft_count: Number(shrubsMoreThan10),
              cycle: frequency,
              desired_clippings_haulaway: haulOff,
              details,
              instant_quote_suggestion_id: null,
              locations,
              property_id: propertyId,
              service_promo_id: params.get('service_promo_id'),
              type: 'bush_trimming',
              pendingImages: images,
            },
          }),
        );
        setShowQuote(true);
        scrollToBottom();
      }
    },
    [validateBushes, dispatch, params, propertyId, setShowQuote],
  );

  const approve = async () => {
    const result = await dispatch(instantQuote_acceptQuote({ property_id: propertyId }));

    if (result?.success) {
      navigate(WebRoutes.services, { options: { replace: true } });
    }
  };

  const reject = () => {
    rejectQuoteModal.show({
      isAnswerRequired: false,
      origin: 'instant_quote_leaf_removal_form',
      title: t('instantQuote.viewer.rejectFeedback.title'),
      question: t('instantQuote.viewer.rejectFeedback.reason'),
      questionPlaceholder: t('forms.enterDetailsHere'),
      buttonText: t('instantQuote.viewer.rejectButton'),
      onSubmit: onSubmitReject,
    });
  };

  const onSubmitReject = async ({ answer }: { answer?: string }) => {
    const result = await dispatch(
      instantQuote_rejectQuote({
        property_id: propertyId,
        formData: {
          acceptable_price: 0,
          notes: answer || null,
        },
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.browseRedirect, { options: { replace: true } });
    }
  };

  return (
    <View style={{ padding: theme.spacing.s5 }}>
      <OrderServiceHeader
        section={t('instantQuoteFor')}
        serviceName={t('bushTrimmingForm.title.bushTrimming')}
      />

      <BushTrimmingForm control={control} />

      {instantQuote && showQuote ? (
        <GeneratedInstantQuoteViewer
          property_id={propertyId}
          currentQuote={instantQuote}
          isUpdating={isLoading}
          onApprove={approve}
          onReject={reject}
        />
      ) : (
        <Button
          onPress={handleSubmit(submit)}
          trackID="instant_quote_order_flow_photos_screen-next"
          mode="contained"
          disabled={isLoading}
          loading={isLoading}
        >
          {t('generateQuote')}
        </Button>
      )}
    </View>
  );
}
