import { useMemo } from 'react';
import { Image } from 'react-native';
import LawnTreatmentPhoto from '@lawnstarter/customer-modules/assets/services/lawn_treatment.png';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { StyledAboutContainter, stylesLegacy } from './styles';

import type { ImageSourcePropType } from 'react-native';

export function AboutLawnTreatmentLegacy({ showSchedule }: { showSchedule: () => void }) {
  const theme = useAppTheme();
  const style = useMemo(() => stylesLegacy(theme), [theme]);
  return (
    <StyledAboutContainter>
      <Text variant="titleLarge" style={[style.title, style.boldFont]}>
        {t('ltrs.aboutLawnTreatmentLegacy.title')}
      </Text>

      <Image source={LawnTreatmentPhoto as ImageSourcePropType} style={style.image} />

      <ul className="list-disc" style={style.list}>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutRoots')}</Text>
        </li>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutWeeds')}</Text>
        </li>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutGrowth')}</Text>
        </li>
      </ul>

      <Button
        onPress={showSchedule}
        trackID="about_lawn_treatment_screen-next"
        mode="contained"
        style={style.button}
      >
        {t('ltrs.aboutLawnTreatmentLegacy.callToAction')}
      </Button>
    </StyledAboutContainter>
  );
}
