import { useMemo } from 'react';
import { Divider } from 'react-native-paper';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { StyledRequestContainer, StyledScheduleContainter, styles } from './styles';

export function ScheduleLawnTreatment({
  price,
  submitRequest,
  isLoading = false,
}: {
  price: string | number;
  submitRequest: () => void;
  isLoading?: boolean;
}) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  return (
    <StyledScheduleContainter>
      <div>
        <Text variant="titleLarge" style={[style.title, style.boldFont]}>
          {t('ltrs.scheduleLtrsLegacy.header.primary')}
        </Text>
        <br />
        <Text variant="titleMedium" style={[style.title, style.boldFont, style.price]}>
          {price ? currency({ amount: price }) : <Spinner />}
        </Text>
        <Text>{t('ltrs.scheduleLtrsLegacy.header.secondary')}</Text>
        <Divider style={style.divider} />
      </div>

      <StyledRequestContainer>
        <Text variant="titleMedium" style={style.title}>
          {t('ltrs.scheduleLtrsLegacy.title')}
        </Text>
        <Text>{t('ltrs.scheduleLtrsLegacy.threeWeekWindow')}</Text>
        <Text>{t('ltrs.scheduleLtrsLegacy.weeksApart')}</Text>
      </StyledRequestContainer>

      <Button
        style={style.button}
        trackID="order_lawn_treatment_screen-submit_order"
        mode="contained"
        loading={isLoading}
        onPress={submitRequest}
      >
        {t('submitRequest')}
      </Button>

      <Text variant="bodySmall">{t('ltrs.scheduleLtrsLegacy.chargedUntilCompletion')}</Text>
    </StyledScheduleContainter>
  );
}
