import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LTRS_FAQ_ITEMS_LEGACY } from '@lawnstarter/customer-modules/constants';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  orderServices_orderPricedLtrsService,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { Faq, Question } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { AboutLawnTreatmentLegacy, ScheduleLawnTreatment } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { StyledFaqContainer, StyledOrderLawnTreatmentContainter, styles } from './styles';

export function OrderLawnTreatmentScreen() {
  const [showSchedule, setShowSchedule] = useState(false);
  const { email: customerEmail } = useSelector(auth_signedUserSelector);
  const { navigate } = useRouteNavigation();
  const { propertyId, servicePromoId } = useParams();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const dispatch = useDispatch();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );

  const onSubmit = async () => {
    const { success, error } = await dispatch(
      orderServices_orderPricedLtrsService({
        property_id: propertyId,
        service_promo_id: servicePromoId,
        service_id: lawnTreatmentInfo?.service.id,
        price_id: lawnTreatmentInfo?.options[0].price_id,
      }),
    );

    if (success) {
      const message = t('confirmationServiceOrder.message', {
        customerEmail,
        serviceName: lawnTreatmentInfo?.service.name,
      });

      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('confirmationServiceOrder.title'),
        messages: [message],
        buttonText: t('goToMyServices'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  return (
    <StyledOrderLawnTreatmentContainter>
      {showSchedule ? (
        <ScheduleLawnTreatment
          price={lawnTreatmentInfo?.options[0].price ?? ''}
          submitRequest={onSubmit}
          isLoading={isUpdating}
        />
      ) : (
        <AboutLawnTreatmentLegacy
          showSchedule={() => {
            setShowSchedule(!showSchedule);
          }}
        />
      )}

      <StyledFaqContainer>
        <Faq header={t('customerFaq.faq')} style={{ wrapperScroll: { height: 300 } }}>
          {
            LTRS_FAQ_ITEMS_LEGACY.map((item, index) => (
              <Question key={`faq:${index}`} question={item.title} trackID="" onPress={() => {}}>
                <Text style={style.faqInfo}>{item.content}</Text>
              </Question>
            )) as any
            // TODO: Once the Faq component resolves child type and style options remove "as any"
          }
        </Faq>
      </StyledFaqContainer>
    </StyledOrderLawnTreatmentContainter>
  );
}
