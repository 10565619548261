import { Pressable } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import { HEADER_ROUTE_OPTIONS } from '@src/constants';

import { StyledFooter } from './styles';

import type { CurrentTabProps } from '@src/types';

export function FooterNavbar({ currentTab }: CurrentTabProps) {
  const navigate = useNavigate();
  const theme = useAppTheme();

  return (
    // switched to px because it was not showing up with the temp literal of theme size.
    <StyledFooter style={{ boxShadow: `0 0 4px ${theme.colors.gray.secondary}` }}>
      {HEADER_ROUTE_OPTIONS.map(({ link, label, icon, tab }) => {
        const itemColor = {
          color: currentTab === tab ? theme.colors.primary : theme.colors.gray.primary,
        };

        return (
          <Pressable key={label} onPress={() => navigate(link)}>
            <div className="flex flex-col justify-center items-center">
              <Icon name={icon as any} size={theme.sizing.s7} color={itemColor.color} />
              <Text variant="bodyMedium" style={itemColor}>
                {label}
              </Text>
            </div>
          </Pressable>
        );
      })}
    </StyledFooter>
  );
}
