import tw from 'tailwind-styled-components';

export const StyledFormContainer = tw.div`
  max-md:mx-4 max-md:mt-4
`;

export const StyledButtonWrapper = tw.div`
  my-2
  md:w-52
`;
