import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  auth_checkForTokenAndLogin,
  auth_signedUserSelector,
} from '@lawnstarter/customer-modules/stores/modules';

import { Loader } from '@src/components';
import { useDispatch, useSegment, useSelector } from '@src/hooks';
import { GuestTemplate, NoMatch, PasswordRecoveryScreen, SignInScreen } from '@src/pages';
import { authService } from '@src/services';

import { BrowseRouter } from './BrowseRouter';
import { InboxRouter } from './InboxRouter';
import { ProfileRouter } from './ProfileRouter';
import { PropertyRouter } from './PropertyRouter';
import { ServicesRouter } from './ServicesRouter';

export function GlobalRouter() {
  const dispatch = useDispatch();
  const [isAppStarting, setAppStarting] = useState(true);

  const user = useSelector(auth_signedUserSelector);
  useSegment();
  const authLoadingStatuses = useSelector(({ auth }) => auth.loadingStatus);

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('auth');
      const intent = url.searchParams.get('intent');

      // if the user is coming from sign up, the url will have a token parameter
      if (token) {
        await authService.setToken(token);

        // remove the token from the URL
        url.searchParams.delete('auth');

        // if the url contains an intended URL, redirect there
        const targetUrl =
          intent && !!intent.length ? `${window.location.origin}${intent}` : url.href;
        url.searchParams.delete('intent');

        history.replaceState(history.state, '', targetUrl);
      }

      dispatch(auth_checkForTokenAndLogin()).then(() => setAppStarting(false));
    })();
  }, [dispatch]);

  if (isAppStarting || authLoadingStatuses?.isInitializing || authLoadingStatuses?.isLoading) {
    return <Loader isRouterLoader />;
  }

  const routes = createBrowserRouter([
    { path: '*', element: <NoMatch /> },
    {
      path: WebRoutes.root,
      element: <GuestTemplate user={user} />,
      children: [
        { path: WebRoutes.login, element: <SignInScreen /> },
        { path: WebRoutes.passwordRecovery, element: <PasswordRecoveryScreen /> },
      ],
    },
    ...BrowseRouter({ user }),
    ...InboxRouter({ user }),
    ...ProfileRouter({ user }),
    ...PropertyRouter({ user }),
    ...ServicesRouter({ user }),
  ]);

  return <RouterProvider router={routes} />;
}
