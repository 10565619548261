import { BRAND_SLUG } from '@env';
import { useEffect, useRef, useState } from 'react';

import { errorService } from '@src/services';

import type { FC, SVGProps } from 'react';

export function useDynamicSvgImport(name: string) {
  const importedIconRef = useRef<FC<SVGProps<SVGElement>>>(() => <></>);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const importSvgIcon = async (): Promise<void> => {
      try {
        importedIconRef.current = (
          await import(`../assets/${BRAND_SLUG}/${name}.svg`)
        ).ReactComponent;
      } catch (err) {
        errorService.log({ error: err });
      } finally {
        setLoading(false);
      }
    };

    importSvgIcon();
  }, [name]);

  return { loading, Svg: importedIconRef.current };
}
