import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useMowingReactivationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';
import { ActionUnavailable } from '@lawnstarter/ls-react-common/molecules';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { Loader, OrderServiceHeader } from '@src/components';
import { LawnMowingForm } from '@src/components/organisms/forms';
import { useAutoScrollOnFormErrors, useRouteNavigation, useSelector } from '@src/hooks';

import { StyledOrderWrapper } from './styles';

import type { User } from '@lawnstarter/ls-react-common/types';

export function OrderLawnMowingScreen() {
  const theme = useAppTheme();

  const stripe = useStripe();
  const elements = useElements();
  const { navigate } = useRouteNavigation();
  const [params] = useSearchParams();

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const accountBalance = useSelector(
    createSelector(auth_signedUserSelector, (user: User) => user?.customer?.balance),
  );

  const balanceCurrency = useMemo(
    () =>
      currency({
        amount: Math.abs(accountBalance ?? 0),
        emptyDisplay: '$0.00',
      }),
    [accountBalance],
  );

  useEffect(() => {
    if (params.get('deal')) {
      localStorage.setItem('deal', params.get('deal')!);
    }

    return () => {
      localStorage.removeItem('deal');
    };
  }, [params]);

  const {
    control,
    cycles,
    errors,
    handleSubmit,
    isUpdating,
    last4,
    minDate,
    setValue,
    submitCount,
  } = useMowingReactivationController({
    stripe,
    stripeElements: elements,
    onSuccessfulOrder: () => {
      navigate(WebRoutes.reactivationPolicies, {
        params: { propertyId },
        options: { replace: true },
      });
    },
  });

  useAutoScrollOnFormErrors({ errors, submitCount });

  // Displays loader if loading cycles or performing date setup + format
  if (minDate.length !== 10) {
    return <Loader />;
  }

  if (cycles.length === 0) {
    return (
      <div className="flex flex-col items-center">
        <ActionUnavailable
          title={t('activateLawnMowingServiceUnavailable')}
          description={t('pleaseContactBrand')}
          action={t('exploreNow')}
          fallbackAction={() => navigate(WebRoutes.browseRedirect)}
        />
      </div>
    );
  }

  function renderPendingPaymentNotice() {
    if ((accountBalance ?? 0) >= 0) {
      return null;
    }

    return (
      <Notice type={NoticeType.Hint}>
        <div className="pt-3 pb-5 flex flex-col gap-4">
          <div className="flex flex-row items-center gap-3">
            <Icon
              name={'information-outline'}
              color={theme.colors.primary}
              size={theme.sizing.s7}
            />
            <Text
              variant={'labelLarge'}
              style={{
                ...setFontWeight('700'),
                color: theme.colors.primary,
              }}
            >
              {t('pendingPayment.balanceWillBeProcessed')}
            </Text>
          </div>
          <Text>{t('pendingPayment.outstandingBalance', { value: balanceCurrency })}</Text>
        </div>
      </Notice>
    );
  }

  return (
    <>
      <OrderServiceHeader
        className="max-md:py-4 max-md:pb-0"
        serviceName={t('activateLawnMowingService')}
      />
      <StyledOrderWrapper>
        <form>
          <div className="my-4 gap-4 flex flex-col">
            <LawnMowingForm
              cycles={cycles}
              minDate={minDate}
              control={control}
              setValue={setValue}
              isUpdating={isUpdating}
              submitCount={submitCount}
              currentCard4Digits={last4}
            />

            {renderPendingPaymentNotice()}
          </div>

          <Button
            mode="contained"
            loading={isUpdating}
            onPress={handleSubmit}
            trackID="browse-screen_order-mowing-submit-btn"
          >
            {t('forms.completeBooking')}
          </Button>
        </form>
      </StyledOrderWrapper>
    </>
  );
}
