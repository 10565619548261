import { API_BASE_URL, APP_NAME, APP_VERSION, ENV, SEGMENT_KEY, SENTRY_PROJECT_SLUG } from '@env';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import { AppEnv } from '@lawnstarter/ls-react-common/enums';
import {
  createApiService,
  createAuthService,
  createEngagementService,
  createErrorService,
  createHeaderService,
  createTrackingService,
} from '@lawnstarter/ls-react-common/services';

// Cross-platform services
export const authService = createAuthService();

export const engagementService = createEngagementService();

export const errorService = createErrorService({
  Sentry,
  env: ENV,
  appName: APP_NAME,
  appVersion: APP_VERSION,
  sentryProjectSlug: SENTRY_PROJECT_SLUG,
});

export const trackingService = createTrackingService({
  analytics: AnalyticsBrowser.load({ writeKey: SEGMENT_KEY }),
  engagementService,
  appName: APP_NAME,
  appVersion: APP_VERSION,
  env: ENV as AppEnv,
  appEvents: {},
});

export const headersService = createHeaderService({
  appName: APP_NAME,
  appVersion: APP_VERSION,
  authService,
  engagementService,
});

export const apiService = createApiService({
  apiBaseUrl: API_BASE_URL,
  errorService,
  headersService,

  // We'll rely on the navigator to tell if it's online or not
  isDeviceOnline: () => Promise.resolve(navigator.onLine),
});
