import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const stylesLegacy = (theme: AppTheme) => {
  return StyleSheet.create({
    boldFont: {
      ...setFontWeight('600'),
    },
    title: {
      color: theme.colors.primary,
      marginLeft: theme.spacing.s2,
    },
    image: {
      height: 120,
      width: 192,
    },
    list: {
      marginLeft: theme.spacing.s4,
    },
    button: {
      width: '100%',
    },
  });
};

export const StyledAboutContainter = tw.div`flex flex-col items-center justify-center gap-10 w-11/12 mx-auto mt-5`;
