import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { MQSubmitErrorModal, MQSubmitSuccessModal } from '@lawnstarter/customer-modules/helpers';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { GenericQuoteForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { checkboxParser } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
  useWindowSize,
} from '@src/hooks';

import { StyledButtonWrapper, StyledFormContainer } from './styles';

import type {
  GenericFormFieldData,
  GenericQuoteFormData,
} from '@lawnstarter/ls-react-common/organisms';

export function GenericQuoteServiceScreen({ serviceType }: { serviceType: ServiceType }) {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const serviceFlags = useNewServicesFlags();
  const MQStepsModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  if (serviceFlags[serviceType] === false) {
    navigate(WebRoutes.services);
  }

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<GenericQuoteFormData>();

  const serviceInfo = useMemo(() => {
    switch (serviceType) {
      case ServiceType.Weeding:
        return { id: 77, name: t('order.weeding.title') };
      default:
        return { id: 18, name: t('order.other.title') };
    }
  }, [serviceType]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    locations,
    pendingImages,
    desired_work,
  }: GenericQuoteFormData) => {
    const result = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: serviceInfo.id,
          service_str_id: serviceType,

          // Form data
          budget,
          pendingImages,
          desiredWorkDetails: desired_work,
          locations: checkboxParser(locations, ExtendedYardLocations),
        },
      }),
    );

    if (result?.success) {
      QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
      return;
    }

    QuoteSubmittedModal.show(MQSubmitErrorModal());
  };

  return (
    <StyledFormContainer>
      <GenericQuoteHeader serviceName={serviceInfo.name} />

      <div role="form" aria-labelledby="formTitle" className="flex flex-col">
        <GenericQuoteForm
          control={control}
          isUpdating={false}
          fieldData={fieldData(serviceType)}
          infoModal={() => {
            MQStepsModal.show({
              title: t('quote.manualQuote.steps.howQuotingWorks'),
              steps: [
                t('quote.manualQuote.steps.step1'),
                t('quote.manualQuote.steps.step2'),
                t('quote.manualQuote.steps.step3'),
                t('quote.manualQuote.steps.step4'),
              ],
            });
          }}
        />

        <StyledButtonWrapper>
          <Button trackID="submit" testID="submit" mode="contained" onPress={handleSubmit(submit)}>
            {t('recommendations.getQuote')}
          </Button>
        </StyledButtonWrapper>
      </div>
    </StyledFormContainer>
  );
}

function GenericQuoteHeader({ serviceName }: { serviceName: string }) {
  const { width } = useWindowSize();

  const fontVariant = useMemo(() => {
    if (width <= DEVICE_WIDTH_BREAKPOINTS.mobile.max) {
      return 'headlineSmall';
    }

    if (
      width > DEVICE_WIDTH_BREAKPOINTS.mobile.max &&
      width <= DEVICE_WIDTH_BREAKPOINTS.tablet.max
    ) {
      return 'headlineMedium';
    }

    return 'displaySmall';
  }, [width]);

  return (
    <div className="mb-4" id="formTitle">
      <Text variant={fontVariant} style={{ ...setFontWeight('600') }}>
        {t('forms.quote.request')} {serviceName}
      </Text>
    </div>
  );
}

function fieldData(serviceType: ServiceType): GenericFormFieldData {
  let checkboxOptions;
  let textAreaOptions;

  switch (serviceType) {
    case ServiceType.Weeding:
    default:
      checkboxOptions = {
        title: t('forms.genericQuote.weeding.where'),
        options: ExtendedYardLocations,
      };
      textAreaOptions = {
        label: t('forms.genericQuote.weeding.what'),
        placeholder: t('forms.genericQuote.describe'),
      };
      break;
  }

  return {
    checkboxOptions,
    textAreaOptions,
    budgetOptions: {
      label: t('forms.genericQuote.budget'),
      placeholder: '0.00',
    },
    imageUploadOptions: {
      label: t('forms.genericQuote.photos'),
      tooLargeFilesText: t('forms.upload.fileTooLarge'),
      uploadText: t('forms.upload.uploading'),
      addButton: { text: t('forms.uploadPhotos') },
    },
  };
}

// This function is temporary and shall be removed after PE-40767
function useNewServicesFlags(): Partial<Record<ServiceType, boolean>> {
  return {
    [ServiceType.Weeding]: useFeatureFlag(FeatureFlags.ALLOW_WEED_PULLING_ORDER_WEB),
    [ServiceType.TreeTrimming]: useFeatureFlag(FeatureFlags.ALLOW_TREE_TRIMMING_ORDER_WEB),
    [ServiceType.LawnSeeding]: useFeatureFlag(FeatureFlags.ALLOW_LAWN_SEEDING_ORDER_WEB),
    [ServiceType.Sodding]: useFeatureFlag(FeatureFlags.ALLOW_SODDING_ORDER_WEB),
    [ServiceType.ShrubOrTreePlanting]: useFeatureFlag(FeatureFlags.ALLOW_TREE_PLANTING_ORDER_WEB),
  };
}
