import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Divider } from 'react-native-paper';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_orderPricedLtrsService,
  properties_currentPropertySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeEnum } from '@lawnstarter/ls-react-common/enums';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, Notice } from '@lawnstarter/ls-react-common/organisms';

import { AerationForm } from '@src/components/organisms/forms';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledOrderDetails, StyledOrderWrapper, styles } from './styles';

import type { AerationFormData } from '@src/components/organisms/forms';

export function OrderAerationScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const { navigate } = useRouteNavigation();
  const dispatch = useDispatch();
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const property = useSelector(properties_currentPropertySelector);

  const { price, service } = useMemo(() => {
    const { options, service } = property?.priced?.services?.aeration ?? {
      options: [{}],
      service: {},
    };
    const [price] = options;

    return {
      price,
      service,
    };
  }, [property?.priced?.services?.aeration]);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<AerationFormData>();

  const submit = async (data: AerationFormData) => {
    const dataToCallAction = {
      price_id: price?.price_id,
      property_id: property.id,
      service_id: service?.service_id,
      starts_at: data.starts_at,
    };

    const { success = false, error } = await dispatch(
      orderServices_orderPricedLtrsService(dataToCallAction),
    );

    if (success) {
      navigate(WebRoutes.services);
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  useAutoScrollOnFormErrors({ errors, submitCount });

  const buildBulletPoints = (bulletPoint: string) => (
    <div key={bulletPoint} className="flex justify-between py-4">
      <Text variant="bodyLarge" style={{ ...setFontWeight('700') }}>
        {bulletPoint}
      </Text>
      <Icon name="check" color={theme.colors.primary} size={theme.sizing.s5} />
    </div>
  );

  const aerationExplanation = () => {
    const aerationHeader = t('aeration.information.header');
    const aerationContent = t('aeration.information.content');
    const aerationBulletPoints = [
      t('aeration.information.bulletPoints.core'),
      t('aeration.information.bulletPoints.watering'),
      t('aeration.information.bulletPoints.soften'),
      t('aeration.information.bulletPoints.healthier'),
    ];

    const joinWithDivider = (result: (JSX.Element | null)[], child: string, index: number) => [
      ...result,
      buildBulletPoints(child),
      index < aerationBulletPoints.length - 1 ? <Divider key={`divider-${child}`} /> : null,
    ];

    return (
      <div>
        <div className="mb-2">
          <Text variant="headlineSmall" style={style.primaryBold}>
            {aerationHeader}
          </Text>
        </div>
        <div className="mb-5">
          <Text
            variant="bodyLarge"
            style={{ color: theme.colors.primary, ...setFontWeight('400') }}
          >
            {aerationContent}
          </Text>
        </div>

        {aerationBulletPoints.reduce(joinWithDivider, [])}
      </div>
    );
  };

  const aerationForm = () => {
    const priceSectionTitle = t('aeration.price.title');
    const priceSectionPrice = currency({ amount: price?.price });
    const priceSectionRecurrency = t('aeration.price.recurrency');
    const priceSectionRecurrencyUnit = t('aeration.price.unit');
    const noticeFirstLineText = t('aeration.notice.serviceWindow');
    const noticeSecondLineText = t('aeration.notice.chargedAfterServiceCompletion');

    const costSections = [
      { textVariant: 'headlineSmall', itens: [priceSectionTitle, priceSectionPrice] },
      { textVariant: 'bodyMedium', itens: [priceSectionRecurrency, priceSectionRecurrencyUnit] },
    ];

    return (
      <StyledOrderDetails>
        {costSections.map(({ textVariant, itens }, index) => (
          <div key={`section-${index}`} className="flex justify-between">
            {itens.map((text) => (
              <Text key={text} variant={textVariant as any} style={style.primaryBold}>
                {text}
              </Text>
            ))}
          </div>
        ))}

        <div className="py-4">
          <Divider key={'divider-price'} />
        </div>

        <form>
          <div className="pb-4">
            <AerationForm control={control} isUpdating={false} />
          </div>

          <Notice type={NoticeEnum.Hint}>
            <div className="pt-3 pb-5 flex flex-col gap-4">
              <Text>{noticeFirstLineText}</Text>
              <Text style={{ ...setFontWeight('600') }}>{noticeSecondLineText}</Text>
            </div>
          </Notice>

          <Button
            mode="contained"
            loading={isUpdating}
            onPress={handleSubmit(submit)}
            style={{ marginTop: theme.spacing.s5 }}
            trackID="browse-screen_order-aeration-submit-btn"
          >
            {t('add', { service: t('services.aeration') })}
          </Button>
        </form>
      </StyledOrderDetails>
    );
  };

  return (
    <StyledOrderWrapper>
      {aerationExplanation()}
      {aerationForm()}
    </StyledOrderWrapper>
  );
}
