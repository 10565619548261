import { useMatch } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

export const useShowHelpIcon = () => {
  const serviceDetails = useMatch(WebRoutes.serviceDetail);
  const recommendedDetails = useMatch(WebRoutes.recommendedQuoteDetails);
  const requiredDetails = useMatch(WebRoutes.requiredQuoteDetails);

  return [serviceDetails, recommendedDetails, requiredDetails].some((match) => !!match);
};
