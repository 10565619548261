import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';

import { OrderServiceHeader } from '@src/components';
import { GenericQuoteForm } from '@src/components/organisms/forms';
import { checkboxParser } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledButtonWrapper, StyledFormContainer } from './styles';

import type { GenericServiceFormData } from '@src/components/organisms/forms';

export function OrderGenericServiceScreen({ serviceType }: { serviceType: ServiceType }) {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<GenericServiceFormData>();

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const serviceInfo = useMemo(() => {
    switch (serviceType) {
      case ServiceType.TreeCare:
        return { id: 3, name: t('order.treeCare.title') };
      case ServiceType.Landscaping:
        return { id: 9, name: t('order.landscaping.title') };
      default:
        return { id: 18, name: t('order.other.title') };
    }
  }, [serviceType]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    locations,
    pendingImages,
    desired_work,
    property_items,
  }: GenericServiceFormData) => {
    const result = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: serviceInfo.id,
          service_str_id: serviceType,

          // Form data
          budget,
          pendingImages,
          itemDetails: property_items,
          desiredWorkDetails: desired_work,
          locations: checkboxParser(locations, ExtendedYardLocations),
        },
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.services);
    }
  };

  return (
    <StyledFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={serviceInfo.name}
        showMQSteps
      />

      <form className="my-4 gap-6 flex flex-col">
        <GenericQuoteForm control={control} isUpdating={false} />

        <StyledButtonWrapper>
          <Button trackID="submit" mode="contained" onPress={handleSubmit(submit)}>
            {t('forms.getPrice')}
          </Button>
        </StyledButtonWrapper>
      </form>
    </StyledFormContainer>
  );
}
