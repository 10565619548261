import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    faqInfo: {
      marginTop: theme.sizing.s3,
      marginLeft: theme.sizing.s1,
    },
  });
};

export const StyledOrderLawnTreatmentContainter = tw.section`
flex flex-col
md:flex-row-reverse md:gap-6
`;

export const StyledFaqContainer = tw.div`
mt-20 w-11/12 mx-auto 
md:w-8/12
`;
